    <template>
    <div class="tech-res">
        <div class="content">
        
            <div class="tech-box">
                <div class="res-category">
                    <div class="res-title">{{hasQuery?'筛选条件':'资源分类'}}</div>

                    <div class="category-content" v-for="(item,oneIndex) in category" :key="item.id">
                        <div class="category-title">{{item.name}}</div>
                        <div class="category-list" v-loading="categoryLoading" v-if="item.list">
                            <div class="category-item" v-for="(data, twoIndex) in item.list" :key="data.id" @click="quickQuery(oneIndex, twoIndex)">{{data.name}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="res-content">
                    
                    <div class="content-title">
						<span>共<span class="highline">{{result.total}}</span>个资源</span>
                        <div class="btns">
                            <button type="button" class="btn btn-outline" v-if="isAdding" @click="cancel">取消</button>
                            <button type="button" class="btn btn-primary" v-if="isAdding" @click="submit" v-loading="submitLoading">提交</button>
                            <button type="button" class="btn btn-primary" v-if="!isAdding" @click="add" >新增</button>
                        </div>
						
					</div>

                    
                    <div class="res-list" v-loading="loading" v-if="!isAdding">
                        <template v-for="item in result.list" :key="item.id">
                            <div class="res-item" >
                                <div class="title" @click="gotoDetail(item.id)" v-html="matchKey(item.nameCn)"></div>
                                <div class="info">
                                    <div class="time">更新时间：{{item.updateTime}}</div>
                                    <div class="dept"  >所属平台：<span class="dept-name" v-if="item.projectName" @click="gotoOrgan(item.projectId)">{{item.projectName}}</span></div>
                                </div>
                                <div class="info">
                                    <div class="time">审核状态：<span :class="{blue:item.examineStatus=='1', error: item.examineStatus!='1'}">{{item.examineStatusStr}}</span></div>
                                    <div class="dept"  v-if="item.examineStatus=='-1'">原因：<span class="error">{{item.why}}</span></div>
                                </div>
                                <div class="desc">
                                        {{item.abstract}}
                                    </div>
                            </div>
                        </template>
                    </div>
                    <Pager v-if="!isAdding" @pageChange="pageChange" class="res-pager" :totalPage="result.page" :currentPage="currentPage"></Pager>
                    
                    <div class="res-content" v-show="isAdding" v-loading="submitLoading">
                        <!--资源详情 or 仪器详情-->
                        <table class="table ins-table">
                            <tr>
                                <td :rowspan="11">基本<br/>信息</td>
                                <td>资源目录<span class="required">*</span></td>
                                <td><select class="form-control" v-model="res.parentId">
                                        <template v-for="(item,oneIndex) in category" :key="item.id">
                                            <option  v-for="(data, twoIndex) in item.list" :key="data.id" :value="data.id">{{data.name}}</option>
                                        </template>
                                    
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>资源名称（中文）<span class="required">*</span></td>
                                <td><input type="text" class="form-control" v-model="res.cataloguetNameCn" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>标识符</td>
                                <td><input type="text" class="form-control" v-model="res.identifier" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>学科分类</td>
                                <td><input type="text" class="form-control" v-model="res.subjectType" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>主题分类</td>
                                <td><input type="text" class="form-control" v-model="res.themeType" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>关键词</td>
                                <td><input type="text" class="form-control" v-model="res.keyWord" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>描述</td>
                                <td><input type="text" class="form-control" v-model="res.describeContent" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>资源生成日期</td>
                                <td>
                                    <el-date-picker
                                        class="myDate"
                                        v-model="res.createTime"
                                        type="date"
                                        placeholder="请选择日期"
                                        value-format="YYYY-MM-DD"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>最近发布日期</td>
                                <td>
                                    <el-date-picker
                                        class="myDate"
                                        v-model="res.releaseTime"
                                        type="date"
                                        placeholder="请选择日期"
                                        value-format="YYYY-MM-DD"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td>资源信息链接发布地址</td>
                                <td><input type="text" class="form-control" v-model="res.dataUrl" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>图片</td>
                                <td>
                                    <el-upload
                                        class="uploader-box"
                                        :action="uploadUrl"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload"
                                    >
                                        <img v-if="res.img" :src="toImage(res.img)" class="uploader-img" />
                                        <el-icon v-else class="uploader-icon"><Plus /></el-icon>
                                    </el-upload>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">所有者<br/>信息</td>
                                <td>所有者</td>
                                <td><input type="text" class="form-control" v-model="res.ownerName" placeholder="请输入"/></td>
                            </tr>
                            
                            <tr>
                                <td rowspan="3">共享<br/>方式</td>
                                <td>共享途径</td>
                                <td>
                                    <div class="radio-group">
                                        <label>
                                            <input type="radio"  class="form-radio" v-model="res.shareType" value="0">线下共享
                                        </label>
                                        <label>
                                            <input type="radio"  class="form-radio" v-model="res.shareType" value="1">线上共享
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>共享范围</td>
                                <td>
                                    <div class="radio-group">
                                        <label>
                                            <input type="radio"  class="form-radio" v-model="res.shareRange" value="0">完全共享
                                        </label>
                                        <label>
                                            <input type="radio"  class="form-radio" v-model="res.shareRange" value="1">有条件共享
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>申请流程</td>
                                <td><input type="text" class="form-control" v-model="res.applicationProcess" placeholder="请输入"/></td>
                            </tr>

                            <tr>
                                <td rowspan="5">资源提交<br/>机构信息</td>
                                <td>提交机构名称</td>
                                <td><input type="text" class="form-control" v-model="res.subMechanismName" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>提交机构通信地址</td>
                                <td><input type="text" class="form-control" v-model="res.subMechanismAddress" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>提交机构邮政编码</td>
                                <td><input type="text" class="form-control" v-model="res.subMechanismPostalCode" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>提交机构联系电话</td>
                                <td><input type="text" class="form-control" v-model="res.subMechanismContactNumber" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>提交机构电子邮箱</td>
                                <td><input type="text" class="form-control" v-model="res.subMechanismEmail" placeholder="请输入"/></td>
                            </tr>

                            <tr>
                                <td rowspan="5">资源服务<br/>机构信息</td>
                                <td>服务机构名称</td>
                                <td><input type="text" class="form-control" v-model="res.serviceMechanismName" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>服务机构通信地址</td>
                                <td><input type="text" class="form-control" v-model="res.serviceMechanismAddress" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>服务机构邮政编码</td>
                                <td><input type="number" class="form-control" v-model="res.serviceMechanismPostalCode" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>服务机构联系电话</td>
                                <td><input type="text" class="form-control" v-model="res.serviceMechanismContactNumber" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>服务机构电子邮箱</td>
                                <td><input type="email" class="form-control" v-model="res.serviceMechanismEmail" placeholder="请输入"/></td>
                            </tr>

                            <tr>
                                <td rowspan="5">标识<br/>信息</td>
                                <td>类目名称</td>
                                <td><input type="text" class="form-control" v-model="res.categoryName" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>类目代码</td>
                                <td><input type="text" class="form-control" v-model="res.categoryCode" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>分类标准名称</td>
                                <td><input type="text" class="form-control" v-model="res.classificationStandardName" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>分类标准版本号</td>
                                <td><input type="text" class="form-control" v-model="res.classificationStandardVersionNumber" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>科技资源引用标注建议</td>
                                <td><input type="text" class="form-control" v-model="res.proposal" placeholder="请输入"/></td>
                            </tr>

                            <tr>
                                <td rowspan="2">标识注册<br/>机构信息</td>
                                <td>注册机构名称</td>
                                <td><input type="text" class="form-control" v-model="res.identificationRegisterMechanismName" placeholder="请输入"/></td>
                            </tr>
                            <tr>
                                <td>注册机构通信地址</td>
                                <td><input type="text" class="form-control" v-model="res.identificationRegisterMechanismAddress" placeholder="请输入"/></td>
                            </tr>

                            
                        </table>
                    </div>
                </div>

                
            
            </div>
        </div>
    </div>
</template>

<script>
import Pager from "../components/common/Pager"
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import city from '../city'
import { ElUpload, ElButton, ElIcon, ElDatePicker} from 'element-plus'
import { Download, Plus } from '@element-plus/icons-vue'
import {ref, reactive, onMounted,computed, watch, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    components:{ Pager, Datepicker: VueDatepickerUi, ElUpload, ElButton, ElIcon, Download, Plus, ElDatePicker },
    setup(){
        const {proxy} = getCurrentInstance();
        const date = ref();
        const loading = ref(false)
        const currentPage = ref(1)
        const queryForm = reactive({
            nameCn: '',
            nameEn: '',
            address: '',
            projectName:'',
            resourcesCatalogueId: '',
            startTime:'',
            endTime: '',
            dataTypeId: ''
        });

        const oneSelect = ref(0);
        const twoSelect = ref(0);

        const categoryLoading = ref(false)
        const categoryList = ref([]);
        const category = ref([])
        

        const result = ref([]);
		
        const route = useRoute();
        const id = ref('');
        const name = ref('');

        if(route.query && route.query.id && route.query.name){
            id.value = route.query.id;
            name.value = route.query.name;
            let ids = id.value.split(',');
            let names = name.value.split(',');
            for(let i = 0 ; i < ids.length; i++){
                category.value.push({id:ids[i], name: names[i]});
            }
            
        }

        if(route.query && route.query.keyword){
            queryForm.nameCn = route.query.keyword;
        }

        const res = ref({
            parentId:'',
            cataloguetNameCn:'',
            cataloguetNameEn: '',
            img: '',
            identifier: '',
            subjectType:'',
            themeType: '',
            keyWord: '',
            describeContent: '',
            releaseTime: '',
            createTime: '',
            isLocalData: '',
            dataUrl: '',
            shareType: '',
            shareRange: '',
            applicationProcess: '',
            ownerName: '',
            subMechanismName: '',
            subMechanismAddress: '',
            subMechanismPostalCode: '',
            subMechanismContactNumber: '',
            subMechanismEmail: '',
            serviceMechanismName: '',
            serviceMechanismAddress: '',
            serviceMechanismPostalCode: '',
            serviceMechanismContactNumber: '',
            serviceMechanismEmail: '',
            categoryName: '',
            categoryCode: '',
            classificationStandardName:'',
            classificationStandardVersionNumber: '',
            proposal:'',
            identificationRegisterMechanism: '',
            identificationRegisterMechanismAddress: ''
        });
        


        function loadResData(){
            loading.value = true;
            const url = '/hfapplication/gdsti/a/resourcesCatalogue/resourcesCatalogueListPageNoExamineStatus';
            if(twoSelect.value >= 0){
                queryForm.resourcesCatalogueId = category.value[oneSelect.value].list[twoSelect.value].id;
            }
            if(oneSelect.value >= 0){
                // queryForm.resourcesCatalogueId = category.value[oneSelect.value].id;
                queryForm.dataTypeId = category.value[oneSelect.value].id;
            }

            queryForm.limit = 10;
            queryForm.offset = (currentPage.value-1)*10;
            proxy.$http.post(url, queryForm).then(res=>{
                loading.value = false
                if(res.data.state == 0){
					result.value = [];
                    result.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        watch(date, (newVal)=>{
            console.log(newVal)
            if(newVal && newVal.length == 2){
                const startTime = newVal[0];
                const endTime = newVal[1];
                if(startTime){
                    queryForm.startTime = formateDate(startTime) + ' 00:00:00';
                }
                if(endTime){
                    queryForm.endTime =  formateDate(endTime) + ' 23:59:59';
                }
            }
        })

        function formateDate(date){
            let month = (date.getMonth()+1);
            if(month < 10){
                month = '0'+month;
            }
            let day =  date.getDate();
            if(day < 10){
                day = '0' + day;
            }
            return date.getFullYear() + '-' + month + '-' + day;
        }

        function loadCategory(categoryItem){
            categoryLoading.value = true;
            const url = '/hfapplication/gdsti/a/resourcesCatalogue/resourcesCatalogueListByTypeId';
            proxy.$http.post(url, {dataTypeId: categoryItem.id}).then(resonse=>{
                categoryLoading.value = false;
				categoryItem['list'] = [];
                if(resonse.data.state==0){
                    categoryItem['list'] = resonse.data.result;
                }
                res.value.parentId = categoryItem['list'][0].id;
                loadResData();
            }).catch(err=>{
                categoryLoading.value = false;
            });
        }

        function loadAllCategory(){
            const url = '/hfapplication/gdsti/a/index/dataTypelist';
            proxy.$http.post(url).then(res=>{
                console.log("res=>",res)
                if(res.data.state==0){
                    category.value = res.data.result;
                    for(let i = 0 ; i < category.value.length; i++){
                        loadCategory(category.value[i]);
                    }
                }
            }).catch(err=>{

            });
        }
            
        
        const platforms = ref([]);

        function getPlatforms(){
            const url = '/hfapplication/gdsti/a/index/getPlatformTypeList';
            proxy.$http.post(url).then(res=>{
                if(res.data.state == 0){
                    platforms.value = res.data.result;
                    }
                }).catch(err=>{

            })
        }

        const hasQuery = computed(()=>{
            let isQuery = false;
            if(date.value && date.value.length == 2){
                if(date.value[0] != null){
                    isQuery = true;
                }
                
            }
            if(twoSelect.value >= 0){
                isQuery = true;
            }else if(oneSelect.value >= 0){
                isQuery = true;
            }else if(queryForm.nameCn || queryForm.nameEn || queryForm.address || queryForm.projectName){
                isQuery = true;
            }

            return isQuery;
        })

        

        onMounted(()=>{
            getPlatforms();
            if(category.value.length == 0){
                //加载全部
                loadAllCategory();
            }else{
                //加载单个
                for(let i = 0 ; i < category.value.length; i++){
                    loadCategory(category.value[i]);
                }
            }
            
        });

        const uploadUrl = computed(()=>{
            const url =  proxy.indexUrl + '/hf/base/a/fileManage/fileUpload';
            return url;
        });


        const submitLoading = ref(false);
        const isAdding = ref(false);

        function submit(){
            if(!res.value.parentId || !res.value.cataloguetNameCn){
                proxy.ElMessage.error('请输入必填项');
                return;
            }
            submitLoading.value = true;
            const url = '/hfapplication/gdsti/a/resourcesCatalogue/dataCatalogueTypeTnodeadd';
            proxy.$http.post(url, res.value).then(res=>{
                submitLoading.value = false;
                if(res.data.state == 0){
                    isAdding.value = false;
                    for(let i = 0 ; i < category.value.length; i++){
                        loadCategory(category.value[i]);
                    }
                    proxy.ElMessage.success('提交成功');
                }
            }).catch(err=>{
                submitLoading.value = false;
            })
        }

        const cityList = ref(city.cityList);

        

        return {submitLoading, submit, uploadUrl, res, isAdding, platforms,cityList, hasQuery, oneSelect, twoSelect, categoryLoading,categoryList, category, date, loading, currentPage, queryForm, result, loadResData, loadCategory, loadAllCategory}

    },
    watch:{
        $route(newval, oldVal){
            window.location.reload();
        }
    },
    methods:{
        toImage(imgId){
            if(imgId){
                return this.$getImage(imgId);
            }else{
                return '';
            }
        },
        add(){
            this.isAdding = true;
        },
        cancel(){
            this.isAdding = false;
        },
        pageChange:function(index){
            this.currentPage = index;
            this.loadResData();
        },
        gotoDetail(id){
            this.$router.push({name: 'Instrument', path:"/instrument", query: {id: id}})
        },
        searchClick(){
            this.pageChange(1);
        },
        resetClick(){
            this.queryForm.startTime = '';
            this.queryForm.endTime = '';
            this.queryForm.nameCn = '';
            this.queryForm.nameEn = '';
            this.queryForm.projectName = '';
            this.queryForm.address = '';
            if(this.date){
                this.date[0] = null;
                this.date[1] = null;
            }
            this.pageChange(1);
        },
        removeQuery(key){
            if(key === 'oneSelect'){
                this.oneSelect = '';
                this.twoSelect = '';
            }else if(key === 'date'){
                this.date[0] = null;
                this.date[1] = null;
                this.queryForm.startTime = '';
                this.queryForm.endTime = '';
            }else{
                this.queryForm[key] = '';
            }
        },
        quickQuery(one, two){
            this.oneSelect = one;
            this.twoSelect = two;
            this.res.parentId = this.category[one].list[two].id;
            this.resetClick();
        },
        gotoOrgan(id){
            if(id){
                this.$router.push({name: 'Organ', path: '/organ', query:{id:id}});
            }
        },
        matchKey(val){
            if(this.queryForm.nameCn){
                let keyword = this.queryForm.nameCn;
                let replaceReg = new RegExp(keyword, 'ig')
                let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                return val.replace(replaceReg, replaceString);
            }else{
                return val;
            }
        },
        handleAvatarSuccess(response, uploadFile){
            if(response.state == 0){
                this.res.img = response.result.list[0].attachInfo.attachCode;
            }else{
                this.ElMessage.error(response.message);
            }
        },
        beforeAvatarUpload(rawFile){
            if(rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png'){
                this.ElMessage.error('图片需要jpg或png格式');
                return false;
            }else if(rawFile.size/1024/1024 > 2){
                this.ElMessage.error('图片需要小于2MB');
                return false;
            }
            return true;
        }
    }
}
</script>

<style lang="less" scoped>
@import url('../../public/static/css/techRes.less');

.ins-table{
        margin-top: 16px;
        margin-bottom: 42px;
        border-right: 1px solid rgb(192, 225, 254);
        border-top: 1px solid rgb(192, 225, 254);

        tr td{
            background: rgb(241, 248, 255);
            text-align: right;
            padding: 16px;
            border-bottom: 1px solid rgb(192, 225, 254);
        }

        tr td[rowspan]{
            background: rgb(0, 127, 236);
            color: #fff;
            text-align: center;
            border-bottom: 1px solid #fff;
            width: 74px;
        }

       
        tr td:last-child{
            background: #fff;
            text-align: left;
            width:540px;
        }
        
    }
.btns{
    display: flex;
    button{
        margin-left: 12px;
    }
}
.tech-res{
    background-position: 0 0;
}

.search-form{
    width: 100%;

    #techDate{
        display: inline-block;
    }
    
    
    .search-title{
        padding-top: 33px;
        font-family: SourceHanSansCN-Bold;
        font-size: 20px;
        color: rgba(0,0,0,0.85);
    }
    .search-icon{
        margin-right: 8px;
        color: white;
    }
    .reset-icon{
        margin-right: 8px;
        width: 16px;
        color: #007EEC;
    }
    .search-box{
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0 2px 15px 0 rgba(103,183,255,0.20);
        border-radius: 6px;
        padding: 18px 18px 8px 18px;

        .form-control{
            width: 320px;
        }
        select.form-control{
            width: 350px;
        }

        .form-group{
            margin-bottom: 10px;
        }
        .search-split{
            width: 500px;
        }
        .search-btns{
            flex: 1;
            .verhicle-btns{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .btn{
                    width: 135px;
                }
            }
            .btn-clear{
                margin-top: 8px;
            }
        }
    }
}

.sizer-box{
    display: flex;
    align-items: center;
    .sizer-name{
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #2E8CE3;
        margin-right: 12px;
    }
    .sizer-item{
        margin-right: 12px;
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 22px;
        padding-left: 8px;
        background: #007EEC;
        .close{
            cursor: pointer;
            padding-right: 8px;
            padding-left: 8px;
            line-height: 22px;
        }
    }
    .sizer-item.clear{
        cursor: pointer;
        border: 1px solid #007EEC;
        color: #007EEC;
        background: #fff;
        padding-right: 8px;
    }
    .sizer-item.clear:hover{
        background: rgba(103,183,255,0.20);
    }
}
.uploader-box,.myDate{
    margin-left: 10px;
}
.radio-group{
    display: flex;
    align-items: center;
}
.tech-box{
    display: flex;
    justify-content: space-between;
    padding-top: 37px; 

    .res-category{
        width: 283px;
        margin-right: 22px;

        .res-title{
            font-family: SourceHanSansCN-Bold;
            font-size: 18px;
            color: rgba(0,0,0,0.85);
            text-align: center;
            margin-bottom: 18px; 
        }

        .category-content{
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            margin-bottom: 14px; 
            .category-title{
                background: #007EEC;
                line-height: 44px;
                padding-left: 17px;
                padding-right: 17px;
                font-family: SourceHanSansCN-Regular;
                font-size: 18px;
                color: #FFFFFF;
            }
            .category-list{
                padding: 7px 0;
                
                .category-item{
                    cursor: pointer;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 14px;
                    padding: 7px 15px;
                }
                .category-item:hover{
                    color: #bfbfbf;
                }
            }
        }
    }
    .res-content{
        flex: 1;
        max-width: 900px;

        .content-title{
            font-family: SourceHanSansCN-Normal;
            font-size: 20px;
            color: rgba(0,0,0,0.85);
            line-height: 30px;
            margin-bottom: 16px;
			display: flex;
			justify-content:space-between;
        }
        .res-list{
            padding-left: 12px;
            padding-right: 12px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;

            .res-item{

                padding-top: 20px;
                padding-bottom: 16px;
                border-bottom: 1px dashed #E7E7E7;

                .title{
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: SourceHanSansCN-Medium;
                    font-size: 18px;
                    color: #007EEC;
                    cursor: pointer;
                }
                .info{
                    padding-left: 16px;
                    padding-right: 16px;
                    margin-top: 14px;
                    display: flex;
                    .time{
                        font-family: SourceHanSansCN-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                        margin-right: 27px;
                    }
                    .dept{
                        font-family: SourceHanSansCN-Normal;
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                       
                        .dept-name{
                            color: #007EEC;
                            cursor: pointer;
                        }

                    }
                }
                .desc{
                    padding-left: 16px;
                    padding-right: 16px;
                    margin-top: 10px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 14px;
                    color: rgba(0,0,0,0.50);
                    line-height: 17px;
                }
            }
            .res-item:last-child{
                border-bottom: none;
            }
        }
    }
}
.res-pager{
    margin-top: 28px;
    margin-bottom: 28px; 
}
</style>


