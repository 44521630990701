<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="box-title">广州民营科技创展中心</div>
        <div class="industry-img" style="text-align:center;margin-bottom: 74px;">
            <img src="../../../../public/static/img/temp1.png"/>
            <img src="../../../../public/static/img/temp2.png"/>
        </div>

    
        
    </div>
</template>

<script>

import Bread from '../../../components/common/Bread.vue'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    data(){
        return {
            active: 0
        }
    },
    components: {Bread},
    setup(){
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const id = ref(route.query.id);
        const loading = ref(false);
        const breads = ref([
                {
                    text: '产业专区',
                    name: 'Industry',
                    path: '/Industry'
                },{
                    text: '产业专区详情',
                    name: 'IndustryDetail',
                    path: '/IndustryDetail?id='+ route.query.id
                },{
                    text: '广州民营科技创展中心'
                }
            ]);

        

        return {id, loading, breads}
    },
    methods:{

    }
}
</script>

<style lang="less" scoped>
@import url('../../../../public/static/css/industryDetail.less');
</style>

